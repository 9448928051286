<script>
import SectionBlock from '../general/SectionBlock'
import ComboboxSkills from '../general/ComboboxSkills'
export default {
  components: { SectionBlock, ComboboxSkills },
  name: 'NewSkillsForm',
  data () {
    return {
      generalSkillsOptions: [
        'Java',
        'Node.js',
        'HTML',
        'CSS'
      ],
      InterestsOptions: [
        'Técnologia',
        'Agricultura',
        'Carros',
        'Motos',
        'Mecânica',
        'Música'
      ],
      generalSkills: [],
      interests: [],
      tempGeneral: null,
      tempInterests: null,
      skill1: null,
      skill2: null,
      skill3: null
    }
  },
  created () {
    this.$store.dispatch('attemptGetUserProfile').then(data => {
      this.generalSkills = data.skills.map((skill) => skill)
    })
  },
  methods: {
    handleSaveSkills () {
      this.$store.dispatch('attemptUpdateUserProfile', { filter: 'skills', skills: this.generalSkills.map(skill => skill) }).then(() => {
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'success',
          title: this.$t('modal.change.profile.member:feedback.success')
        }).catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('modal.change.profile.member:feedback.error')
          })
        })
      })
    },
    addGeneralSkill (item) {
      setTimeout(() => {
        this.tempGeneral = null
      }, 10)
      if (this.generalSkills.findIndex(i => i === item) !== -1) return
      this.generalSkills.push(item)
    },
    removeGeneralSkill (generalIndex) {
      this.generalSkills = this.generalSkills.filter((_, index) => index !== generalIndex)
    },
    addInterestArea (item) {
      setTimeout(() => {
        this.tempInterests = null
      }, 10)
      if (this.interests.findIndex(i => i === item) !== -1) return
      this.interests.push(item)
    },
    removeInterestArea (interestIndex) {
      this.interests = this.interests.filter((_, index) => index !== interestIndex)
    }
  }
}
</script>
<template>
  <div class="skills-form--container">
     <!-- <section-block title="skills.form:main.skills.title">
      <h4 class="section-title">{{ $t('skills.form:main.skills.question') }}</h4>
      <p class="section-description">{{ $t('skills.form:main.skills.description') }}</p>
      <div class="item-list--wrapper-flex">
        <combobox hide-validation v-model="skill1" outlined small :no-data-text="$t('global:no.data.found')" :items="generalSkillsOptions" :placeholder="$t('skills.form:main.skill') + ' 1'" prepend-inner-icon="mdi-star" append-icon=""></combobox>
        <combobox hide-validation v-model="skill2" outlined small :no-data-text="$t('global:no.data.found')" :items="generalSkillsOptions" :placeholder="$t('skills.form:main.skill') + ' 2'" prepend-inner-icon="mdi-star" append-icon=""></combobox>
        <combobox hide-validation v-model="skill3" outlined small :no-data-text="$t('global:no.data.found')" :items="generalSkillsOptions" :placeholder="$t('skills.form:main.skill') + ' 3'" prepend-inner-icon="mdi-star" append-icon=""></combobox>
      </div>
    </section-block> -->
    <section-block title="skills.form:general.skills.title">
      <combobox-skills v-model="generalSkills"></combobox-skills>
      <!-- <h4 class="section-title">{{ $t('skills.form:general.skills.question') }}</h4>
      <p class="section-description">{{ $t('skills.form:general.skills.description') }}</p>
      <combobox v-model="tempGeneral" outlined small :no-data-text="$t('global:no.data.found')" :items="generalSkillsOptions" @change="addGeneralSkill" :placeholder="$t('global:search')" append-icon="mdi-magnify"></combobox>
      <div class="items-list--wrapper">
        <template v-for="(item, index) in generalSkills">
          <v-chip :key="item">{{ item }}<v-icon class="remove-item" @click="removeGeneralSkill(index)">mdi-close</v-icon></v-chip>
        </template>
      </div> -->
    </section-block>
    <!-- <section-block title="skills.form:interests.themes.title">
      <h4 class="section-title">{{ $t('skills.form:interests.themes.question') }}</h4>
      <p class="section-description">{{ $t('skills.form:interests.themes.description') }}</p>
      <combobox v-model="tempInterests" outlined small :no-data-text="$t('global:no.data.found')" :items="InterestsOptions" @change="addInterestArea" :placeholder="$t('global:search')" append-icon="mdi-magnify"></combobox>
      <div class="items-list--wrapper">
        <template v-for="(item, index) in interests">
          <v-chip :key="item">{{ item }}<v-icon class="remove-item" @click="removeInterestArea(index)">mdi-close</v-icon></v-chip>
        </template>
      </div>
    </section-block> -->
    <div class="skills-form--actions">
      <v-btn class="btn transform-unset" @click="handleSaveSkills" :color="getPrimaryColor" dark>{{ $t('global:save.changes') }}</v-btn>
    </div>
  </div>
</template>
<style lang="scss">
.skills-form--container {
  h4.section-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #454545;
  }
  p.section-description {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #454545;
    margin-bottom: 16px;
  }
  .item-list--wrapper-flex {
    display: flex;
    justify-content: space-between;
    .validation--wrapper {
      width: 30%;
      .v-input__prepend-inner {
        margin-top: 12px;
        .v-icon {
          color: #F7BF34;
        }
      }
    }
  }
  .items-list--wrapper {
    .v-chip {
        background: #FFFFFF !important;
        border: 1px solid rgba(0, 0, 0, 0.06);
        padding: 8px;
        border-radius: 9999px;
        color: #454545;
        font-weight: bold;
        margin-right: 10px;
        .v-icon.remove-item {
          cursor: pointer;
          background: #454545;
          color: #FFFFFF;
          border-radius: 50px;
          margin-left: 6px;
          height: 20px;
          width: 20px;
          font-size: 18px;
        }
      }
  }
  .skills-form--actions {
    text-align: right;
    margin: 40px 0 80px;
  }
}
</style>
